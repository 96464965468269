import React, { useEffect } from "react";
import Page1 from "./Page/Page1";
import Formfield2 from "./Page/Page2";
import Thankyou from "./Page/Page3";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import KeycloakUserService from "../src/Components/login/KeyCloakService";
//router for all page
function App() {
  let userName = KeycloakUserService.getUsername();
  return (
    <div>
      <Router>
        <Routes>
          {/* home page route  */}
          <Route exact path="/" element={<Page1 />}></Route>
          {/* after the next button page route  */}
          <Route exact path="/formfield2" element={<Formfield2 />}></Route>
          {/* after the final submit button page route  */}
          <Route exact path="/thankyou" element={<Thankyou />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
