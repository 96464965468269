import React from "react";
import Thanks_Image from "../../assets/Images/check circleTick.png";
import { Image, Menu, Button } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
const Thankyou = () => {
  const navigate = useNavigate();
  const handleSubmit = function(){
    // localStorage.clear();
    localStorage.removeItem("formval");
    navigate("/")
  }
  return (
    /* Component is used as a Header in webapp */
    <>
      <Menu.Item className="back-to-form">
        <Button className="back-button" onClick={handleSubmit}>
              Back to Home
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Image className="image-item" src={Thanks_Image} circular />
      </Menu.Item>
      <div>
        <Menu.Item className="thanks-text">Thank you!</Menu.Item>
        {/* <Menu.Item className="thanks-text-submitted">
          Your response has been submitted.
        </Menu.Item> */}
      </div>
    </>
  );
};

export default Thankyou;
