import React, { useState } from "react";
import axios from "axios";
import { Button, Form, Menu, Modal } from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import create_rounded from "./../../assets/Images/create_rounded.png";
import delete_forever_rounded from "./../../assets/Images/delete forever_rounded.png";
import "tippy.js/dist/tippy.css";
import KeycloakUserService from "../login/KeyCloakService";
import { v4 as uuidv4 } from 'uuid';
// axios.defaults.timeout = 14400000;

//form field 2 for hold the response
const FormField = () => {
  const [file, setFile] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [downloadFilename, setDownloadFilename] = useState(null);
  const [open, setOpen] = useState(false);
  const [conversionError, setConversionError] = useState(false);
  const [conversionErrorMsg, setConversionErrorMsg] = useState('');
  const [converting, setConverting] = useState(false);
  let localf2val = localStorage.getItem("localf2val");
  let f2vals;
  if (localf2val !== null) {
    f2vals = JSON.parse(localf2val);
  } else {
    f2vals = {
      operational: "",
      revision: "",
      comments: "",
      description: "",
    };
  }
  const [initialval, setInitialval] = useState(f2vals); //initial value for clear the form
  const handelclearevent = () => {
    setDownloadFilename(null);
    setFile(null);
    setConverting(false);
    setInitialval({
      operational: "",
      revision: "",
      comments: "",
      description: "",
    });
  }; //clear event for button handlechange
  const location = useLocation();
  const fileForm1 = location.state && location.state.file;

  function generateTimestamp() {
    var now = new Date();
    
    var day = now.getDate();
    var month = now.getMonth() + 1; // Months are zero-based
    var year = now.getFullYear();
    var hours = now.getHours();
    var minutes = now.getMinutes();
    var seconds = now.getSeconds();
    
    // Add leading zeros if necessary
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    
    var timestamp = day + '-' + month + '-' + year + '-' + hours + '-' + minutes + '-' + seconds;
    return timestamp;
  }

  const onChange = (e) => {
    setInitialval({ ...initialval, [e.target.name]: e.target.value });
    // console.log(initialval);
  };
  const navigate = useNavigate();
  const gotoprevious = () => {
    localStorage.setItem("localf2val", JSON.stringify(initialval));
    navigate("/");
  };
  const gotopreviousAnotherSB = () => {
    // localStorage.clear();
    localStorage.removeItem("formval");
    navigate("/");
  };

  const handleSubmit = () => {
    // Make the API call when the Submit button is clicked
    // let file = localStorage.getItem('pdfFile');
    setConversionError(false);
    if (fileForm1 == null) {
      alert("Please upload a file");
      return;
    }
    const confirmation = window.confirm(
      "Are you sure you want to submit the SB?"
    );
    if (confirmation) {
      let formOne = JSON.parse(localStorage.getItem("formval"));
      delete formOne.effectivlist1;
      delete formOne.effectivlist2;
      delete formOne.effectivlist3;
      delete formOne.effectivlist4;

      fetch(`${process.env.REACT_APP_BACKEND_URL}/airCraft`, {
        method: "POST",
        body: JSON.stringify({
          form2val: initialval,
          form1val: formOne,
          formradioval: JSON.parse(localStorage.getItem("localradioval")),
          formdate: JSON.parse(localStorage.getItem("formdate")),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          let group = JSON.parse(data.data.jsonString).group;
          let configuration = JSON.parse(data.data.jsonString).config;
          let manual = data.data.xmlString;
          let userName = KeycloakUserService.getUsername();
          let timestamp = generateTimestamp();

          const formData = new FormData();
          let headers = {
            headers: {
              "Content-Type": "multipart/form-data",
            }
          };
          // const blob = new Blob([file], { type: 'application/pdf' });
          formData.append("pdf", fileForm1);
          formData.append("group", group);
          formData.append("config", configuration);
          formData.append("manual", manual);
          formData.append("user_id", userName);
          if(localStorage.getItem("session")) {
            formData.append("session_id", localStorage.getItem("session"));
          } else {
            let session_id;
            session_id = uuidv4();
            localStorage.setItem("session", session_id);
          }
          formData.append("timeStamp", timestamp);

          setConverting(true);
          setDownloadFilename(null);
          // Send data to flask application
          setOpen(true);
          axios
            .post(
              `${process.env.REACT_APP_FLASK_APP_URL}/upload`,
              formData,
              headers
            )
            .then((res) => {
              // if (!res.data.error) {
              //   setConversionError(false);
              //   setDownloadFilename(res.data.payload);
              //   setOpen(true);
              // }
              // if (res.data.error === true) {
              //   setConversionError(true);
              //   setConversionErrorMsg(res.data.payload);
              // }
              // setConverting(false);
            })
            .catch((error) => {
              console.error("Error:", error);
              // setConverting(false);
              // setConversionError(true);
            });
          // Redirect to the thank you page
          // navigate("/thankyou");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      console.log("no");
    }
  };
  // const [open, setOpen] = useState(false);
  // const handleButtonClick = () => {
  //   setOpen(true);
  // };

  return (
    //form field for page 2
    <>
      <Form>
        <div className="file-name-sb"> {file} </div>
        <div className={`fromfield-area-2 ${open ? "blur-area" : ""}`}>
          <Menu.Item className="iteam-caution">
            Operational Impact/Caution:
          </Menu.Item>
          <Form.Field>
            <textarea
              onChange={onChange}
              value={initialval.operational}
              name="operational"
              className="text-caution"
            />
          </Form.Field>

          <Menu.Item className="iteam-comments">Revision Comment:</Menu.Item>
          <Form.Field>
            <textarea
              onChange={onChange}
              value={initialval.revision}
              name="revision"
              className="text-comments-field"
            />
          </Form.Field>
          <Menu.Item className="iteam-description">Description:</Menu.Item>
          <Form.Field>
            <textarea
              onChange={onChange}
              value={initialval.description}
              name="description"
              className="input-description"
            />
          </Form.Field>
          <Menu.Item className="iteam-notes">
            General Comments, notes or cautions:
          </Menu.Item>
          <Form.Field>
            <textarea
              onChange={onChange}
              value={initialval.comments}
              name="comments"
              className="input-notes"
            />
          </Form.Field>
          <Button className="edit-button-second">
            <img src={create_rounded} alt="edit logo" height={20} />
            <div>Edit</div>
          </Button>
          <Button
            onClick={handelclearevent}
            type="reset"
            className="clear-button-second"
          >
            <img src={delete_forever_rounded} alt="clear logo" height={20} />
            <div>Clear</div>
          </Button>
          {/* <Link to={"/"}> */}
          <Button className="previous-button" onClick={() => gotoprevious()}>
            {" "}
            Previous
          </Button>
          {/* </Link> */}
          {/* <Link to={"/thankyou"}> */}
          <Button className="submit-button" onClick={handleSubmit}>
            Submit
          </Button>
          {/* </Link> */}
         
          <Menu.Item className="download_link">
            {conversionError && (
              <div>
                <a>Conversion was unsuccessful...</a>
                <br></br>
                <a>Error from conversion process: <br></br>{conversionErrorMsg}</a>
              </div>
            )}
          </Menu.Item>
        </div>
      </Form>

      <Modal
        className="custom-modal"
        dimmer={"blurring"}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <div className="text-conversion-message">
          <a>Conversion has started.....................</a>
          </div>
          <div className="text-conversion">
           <a> You will be notified once it is completed.</a>
           </div>
       
        <Modal.Actions>
          <div className="button-group">
            <Button
              className="download-pop-btn"
              onClick={() => gotopreviousAnotherSB()}
            >
              Process Another SB
            </Button>
            <Button
              className="download-pop-btn"
              onClick={() => navigate("/thankyou")}
            >
              Exit
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default FormField;
