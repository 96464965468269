import React from "react";
import prodLogo from "../../assets/Images/logo_lexx.png";
import { Menu, Image, Button, Grid } from "semantic-ui-react";
import moment from "moment"
import axios from "axios";
import KeycloakUserService from "../login/KeyCloakService";
/* Component is used as a Header in webapp */
const Header = () => {
  const handleLogout = () => {
    localStorage.removeItem("session");
    let userName = KeycloakUserService.getUsername();
    KeycloakUserService.doLogout();
  };
  return (
    <>
        <Menu.Item className="lexx-logo">
          <Image src={prodLogo} />
        </Menu.Item>

        <Menu.Item className="text-mid">
          Service Bulletin (SB) to Engineering Instructions (EI)
        </Menu.Item>
        <Menu.Item className="date">{moment().format(" Do MMMM YYYY")}</Menu.Item>
        <Menu.Item className="logout-button-area">
          <Button className="logout-button" onClick={handleLogout}>
              Logout
          </Button>
        </Menu.Item>
    </>
  );
};

export default Header;
