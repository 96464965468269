import React from "react";
import Header from "../Components/Header/Header";
import FormField from "../Components/Form/FromFiled2";
import File from "../Components/File/UploadFile";
import Files from "../Components/File/ListFiles";


const Page1 = () => {
  /* Component is used as a 2nd page form filed in webapp with header area */
  return (
    <div>
      <div className="heade-area">
        <Header />
      </div>
      {/* <div className="file-upload">
        <File />
      </div> */}
      <div>
        <FormField />
      </div>
      <div className="file-list">
        <Files />
      </div>
    </div>
  );
};

export default Page1;
