import React, { useEffect, useState } from "react";
import { Menu, Form, Button, Modal, Icon, Header } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import arrow_forward from "./../../assets/Images/arrow forward_rounded.png";
import add from "./../../assets/Images/add.png";
import create_rounded from "./../../assets/Images/create_rounded.png";
import delete_forever_rounded from "./../../assets/Images/delete forever_rounded.png";
// import cancle from "./../../assets/Images/cancel.png";
import Tippy from "@tippyjs/react";
import ICONS from "./../../assets/Images/ICONS.png";
import "tippy.js/dist/tippy.css";
import { useLocation } from "react-router-dom";


//form filled for hold the response
const FormField = () => {
  // boeing option state
  const [boeing, setBoeing] = useState("boeing");
  const boeingchange = (value) => {
    setBoeing(value)
  };

  const navigate = useNavigate();
  const [file, setFile] = useState(null);

  // const [open, setOpen] = useState(true);
  // const location = useLocation();
  // const file = location.state && location.state.file;
  let localradioval = localStorage.getItem("localradioval");
  let radiovals;
  if (localradioval !== null) {
    radiovals = JSON.parse(localradioval);
  } else {
    radiovals = {
      Company: false,
      AD: false,
      mbna: false,
      Avionics: false,
      Powerp: false,
      Airframe: false,
      OthersT: false,
      Hangar: false,
      Line: false,
      Shop: false,
      YesDup: false,
      NoDup: false,
      YesEtop: false,
      NoEtop: false,
      DERep: false,
      Customer: false,
      RepNA: false,
    };
  }

  let localradiovalue = localStorage.getItem("localradio");
  let radiovaltoset;
  if (localradiovalue != null) {
    radiovaltoset = JSON.parse(localradiovalue);
  } else {
    radiovaltoset = {
      rd: "",
      rd1: "",
      rd2: "",
      rd3: "",
      rd4: "",
      rd5: "",
    };
  }
  const [radiovalues, setRadiovals] = useState(radiovaltoset);
  const [radiovalstate, setRadiovalstate] = useState(radiovals);
  const handleradiochange1 = (e) => {
    if (radiovalues[e.target.name] === e.target.value) {
      setRadiovals({ ...radiovalues, [e.target.name]: "" });
    } else {
      setRadiovals({ ...radiovalues, [e.target.name]: e.target.value });
    }
    if (e.target.value == "ETYES") {
      setRadiovalstate({ ...radiovalstate, YesEtop: true, NoEtop: false });
    } else {
      setRadiovalstate({ ...radiovalstate, NoEtop: true, YesEtop: false });
    }
  };
  const handleradiochange = (e) => {
    let name = e.target.name;
    setRadiovalstate({ ...radiovalstate, [name]: !radiovalstate[name] });
  };
  let localsetvaldate = localStorage.getItem("formdate");
  let datetoset;
  if (localsetvaldate !== null) {
    datetoset = new Date(JSON.parse(localsetvaldate));
  } else {
    datetoset = null;
  }
  const [selectDate, setSelectDate] = useState(datetoset); //date value for date picker
  const [effctListUpdated, setEffctListUpdated] = useState(false);
  let localsetvalform = localStorage.getItem("formval");
  let valuetobeset;
  if (localsetvalform !== null) {
    valuetobeset = JSON.parse(localsetvalform);
  } else {
    valuetobeset = {
      group: "",
      configuration: "",
      finstruct: "",
      elno: "",
      revision: "",
      customer: "",
      ac: "",
      compliance: "",
      documents: "",
      effectivlist: [],
    };
  }
  const [initialval, setInitialval] = useState(valuetobeset); //inital value for clear the form
  const setFomValLocal = () => {
    localStorage.setItem("formval", JSON.stringify(initialval));
    localStorage.setItem("formdate", JSON.stringify(selectDate));
    localStorage.setItem("localradioval", JSON.stringify(radiovalstate));
    localStorage.setItem("localradio", JSON.stringify(radiovalues));
  };
  const handelclearevent = () => {
    setInitialval({
      group: "",
      configuration: "",
      finstruct: "",
      elno: "",
      revision: "",
      customer: "",
      ac: "",
      compliance: "",
      documents: "",
      effectivlist: [],
    });
    setSelectDate(null);
    setRadiovalstate({
      Company: false,
      AD: false,
      mbna: false,
      Avionics: false,
      Powerp: false,
      Airframe: false,
      OthersT: false,
      Hangar: false,
      Line: false,
      Shop: false,
      YesDup: false,
      NoDup: false,
      YesEtop: false,
      NoEtop: false,
      DERep: false,
      Customer: false,
      RepNA: false,
    });
    setRadiovals({
      rd: "",
      rd1: "",
      rd2: "",
      rd3: "",
      rd4: "",
      rd5: "",
    });
  }; //inintal value for clear event
  const [initialvalEffectiveList, setInitialvalEffectiveList] = useState([]);
  const saveListData = () => {
    setEffctListUpdated(false);
    let savedData = initialvalEffectiveList;
    console.log('initialval.effectivlist1', initialval.effectivlist1, 'initialval.effectivlist1');
    if (initialval.effectivlist1 || initialval.effectivlist2 || initialval.effectivlist3 || initialval.effectivlist4) {
      savedData.push({
        effectivlist1: initialval.effectivlist1,
        effectivlist2: initialval.effectivlist2,
        effectivlist3: initialval.effectivlist3,
        effectivlist4: initialval.effectivlist4,
      });
    }
    setInitialvalEffectiveList(savedData);
    setInitialval({
      ...initialval,
      effectivlist1: "",
      effectivlist2: "",
      effectivlist3: "",
      effectivlist4: "",
      effectivlist: initialvalEffectiveList,
    });
    console.log(initialvalEffectiveList);
  };
  const onChange = (e) => {
    setEffctListUpdated(true);
    setInitialval({ ...initialval, [e.target.name]: e.target.value });
  };
  const handleFile = (event) => {
    let names = [];
    for (let i = 0; i < event.target.files["length"]; i++) {
      names.push(event.target.files[i].name);
    }
    setInitialval({ ...initialval, documents: names });
    console.log(event.target.files);
  };
  const handelNextBtn = () => {
    if (effctListUpdated === true) {
      saveListData();
    }
    if (file == null) {
      alert("Please upload a file");
      return;
    }
    if(boeing==="boeing" && (initialval.group.trim()==="" ||initialval.configuration.trim() === "")){
      if(initialval.group.trim()===""){
        document.getElementById("group").reportValidity();
      }
      else if (initialval.configuration.trim() === "") {
        document.getElementById("configuration").reportValidity();
      }
    }
    else if (
      selectDate === null ||
      initialval.elno.trim() === "" ||
      initialval.revision.trim() === "" ||
      initialval.customer.trim() === "" ||
      initialval.ac.trim() === ""
    ) {
      if (selectDate === null) {
        document.getElementById("date").reportValidity();
      } else if (initialval.elno.trim() === "") {
        document.getElementById("configuration").reportValidity();
      } else if (initialval.revision.trim() === "") {
        document.getElementById("configuration").reportValidity();
      } else if (initialval.customer.trim() === "") {
        document.getElementById("configuration").reportValidity();
      } else if (initialval.ac.trim() === "") {
        document.getElementById("configuration").reportValidity();
      }
    } else {
      navigate("/formfield2", { state: { file } });
      setFomValLocal();
    }
  };
  const handlePDFInput = (event) => {
    setFile(event?.target?.files[0]);
    saveListData();
  };
  // const continueFunc = () => {
  //   if (file) {
  //     setOpen(false);
  //   } else {
  //     alert("Upload SB PDF for conversion");
  //   }
  // };
  return (
    //form filed for page 1 form
    <>
   
         

      <div className={`fromfield-area`}>
        <Menu>
          <Form>
            <Menu.Item className="file-input-box">
              <Tippy content="Please upload Service Bulletin">
                <label htmlFor="pdf" className="file-1">
                  <img src={ICONS} alt="upload logo" height={30} />
                  <div>Select SB PDF </div>
                </label>
              </Tippy>
            </Menu.Item>
            <Menu.Item className="dropdown">
              <span>OEM</span>
            </Menu.Item>
            <select
              className="option-value"
              onChange={(event) => boeingchange(event.target.value)}
              defaultValue={boeing}
            >
              <option selected value="boeing">Boeing</option>
              <option value="airbus">Air Bus</option>
              <option value="others">Others</option>
            </select>

            <Menu.Item className="file-input">
              <input
                style={{ display: "none" }}
                type="file"
                id="pdf"
                className="file"
                name="pdf"
                accept=".pdf"
                onChange={handlePDFInput}
              ></input>
              <p className="file-name">{file ? file.name : ""}</p>
            </Menu.Item>

            <Menu.Item className={`${(boeing==="boeing")?"input-group-required":"form-text-group"}`}>
              <span>GROUP</span>
            </Menu.Item>
            <Form.Field>
              <input
                onChange={onChange}
                value={initialval.group}
                name="group"
                id="group"
                className="input-group"
                required={boeing==="boeing"}
              />
            </Form.Field>
            <Menu.Item className="form-text-ei">
              <span>EI NO</span>
            </Menu.Item>
            <Form.Field>
              <input
                onChange={onChange}
                value={initialval.elno}
                name="elno"
                id="elno"
                className="text-ei"
                required
              />
            </Form.Field>
            <Menu.Item className="form-text-revision">
              <span>REVISION</span>
            </Menu.Item>
            <Form.Field>
              <input
                onChange={onChange}
                value={initialval.revision}
                name="revision"
                id="revision"
                className="text-revision"
                required
              />
            </Form.Field>

            <Menu.Item className={`${(boeing==="boeing")?"form-text-configuration-required":"form-text-configuration"}`}>
              <span> A/C Configuration</span>
            </Menu.Item>
            <Form.Field>
              <input
                onChange={onChange}
                value={initialval.configuration}
                name="configuration"
                id="configuration"
                className="input-configuration"
                required={boeing==="boeing"}
              />
            </Form.Field>
            <Menu.Item className="form-text-date">
              <span>DATE</span>

              <Form.Field>
                {/* react date picker for choose the date */}
                <DatePicker
                  className="date-test"
                  selected={selectDate}
                  onChange={(date) => {
                    setSelectDate(date);
                  }}
                  placeholderText={"dd/mm/yyyy"}
                  id="date"
                  required
                />
              </Form.Field>
            </Menu.Item>
            <Menu.Item className="form-text-customer">
              <span>CUSTOMER</span>
            </Menu.Item>
            <Form.Field>
              <input
                onChange={onChange}
                value={initialval.customer}
                name="customer"
                id="customer"
                className="input-customer"
                required
              />
            </Form.Field>
            <Menu.Item className="form-text-account">
              <span>A/C TYPE</span>
            </Menu.Item>
            <Form.Field>
              <input
                onChange={onChange}
                value={initialval.ac}
                name="ac"
                id="ac"
                className="input-account"
                required
              />
            </Form.Field>
            <Menu.Item className="form-text-compilance">COMPLIANCE</Menu.Item>
            <Form.Field>
              <input
                onChange={onChange}
                value={initialval.compliance}
                name="compliance"
                className="input-compliance"
              />
            </Form.Field>
            <Menu.Item className="form-text-documents">
              SOURCE DOCUMENTS
            </Menu.Item>
            <Form.Field>
              <input
                onChange={onChange}
                value={initialval.documents}
                name="documents"
                className="input-documents"
                id="documents"
              />
            </Form.Field>

            <Tippy content=" Add reference ">
              <button className="add-button">
                <input
                  type="file"
                  multiple
                  name="file"
                  accept=".pdf"
                  className="a"
                  onChange={handleFile}
                />
                <img src={add} alt="add logo" height={22} />
              </button>
            </Tippy>
            <Menu.Item className="form-text-list">
              AIRCRAFT /ENGINE/ COMPONENT EFFECTIVITY LIST
              <div className="ef-table-container">
                <table className="ef-data-table">
                  <thead>
                    <tr>
                      <th>A/C MSN</th>
                      <th>A/C Registration</th>
                      <th>Engine / Componet Serial No</th>
                      <th>Engine / Componet Part No</th>
                    </tr>
                  </thead>
                  <tbody>
                    {initialvalEffectiveList.map((row, index) => (
                      <tr key={index}>
                        <td>{row.effectivlist1}</td>
                        <td>{row.effectivlist2}</td>
                        <td>{row.effectivlist3}</td>
                        <td>{row.effectivlist4}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Menu.Item>
            <Form.Field>
              <input
                onChange={onChange}
                value={initialval.effectivlist1}
                name="effectivlist1"
                className="input-list-1"
                placeholder="A/C MSN"
              />
            </Form.Field>
            <Form.Field>
              <input
                onChange={onChange}
                value={initialval.effectivlist2}
                name="effectivlist2"
                className="input-list-2"
                placeholder="A/C Registration"
              />
            </Form.Field>
            <Form.Field>
              <input
                onChange={onChange}
                value={initialval.effectivlist3}
                name="effectivlist3"
                className="input-list-3"
                placeholder=" Engine / Componet Serial No"
              />
            </Form.Field>
            <Form.Field>
              <input
                onChange={onChange}
                value={initialval.effectivlist4}
                name="effectivlist4"
                className="input-list-4"
                placeholder=" Engine / Componet Part No"
              />
            </Form.Field>
            <Tippy content="Add Effectivity List">
              <button onClick={saveListData} className="aircraft-button">
                <img src={add} alt="add logo" height={22} />
                {/* <div>ADD AIRCRAFT</div> */}
              </button>
            </Tippy>
            <Menu.Item className="form-text-mandated">Mandated By:</Menu.Item>

            <article className="radio-button-1">
              <input
                onClick={handleradiochange}
                checked={radiovalstate.Company}
                type="checkbox"
                id="rd1"
                name="Company"
              />
              <label htmlFor="rd1">Company/Operator</label>
              <input
                onClick={handleradiochange}
                checked={radiovalstate.AD}
                type="checkbox"
                className="margin"
                id="rd2"
                name="AD"
              />
              <label htmlFor="rd2" className="margin-right">
                AD
              </label>
            </article>
            <Menu.Item className="form-text-trades">Trades/Skills:</Menu.Item>

            <article className="radio-button-2">
              <input
                onClick={handleradiochange}
                checked={radiovalstate.Avionics}
                type="checkbox"
                id="rd1"
                name="Avionics"
              />
              <label htmlFor="rd1">Avionics</label>
              <input
                onClick={handleradiochange}
                checked={radiovalstate.Airframe}
                type="checkbox"
                className="margin"
                id="rd2"
                name="Airframe"
              />
              <label htmlFor="rd2" className="margin-right">
                Airframe
              </label>
              <input
                onClick={handleradiochange}
                checked={radiovalstate.Powerp}
                type="checkbox"
                id="rd3"
                name="Powerp"
              />
              <label htmlFor="rd3">Power Plant</label>
              <input
                onClick={handleradiochange}
                checked={radiovalstate.OthersT}
                type="checkbox"
                className="margin"
                id="rd3"
                name="OthersT"
              />
              <label htmlFor="rd3" className="margin-right">
                Others...( )
              </label>
            </article>
            <Menu.Item className="form-text-location">Location:</Menu.Item>
            <article className="radio-button-3">
              <input
                onClick={handleradiochange}
                checked={radiovalstate.Hangar}
                type="checkbox"
                id="rd1"
                name="Hangar"
              />
              <label htmlFor="rd1">Hangar</label>
              <input
                onClick={handleradiochange}
                checked={radiovalstate.Line}
                type="checkbox"
                className="margin"
                id="rd2"
                name="Line"
              />
              <label htmlFor="rd2" className="margin-right">
                Line
              </label>
              <input
                onClick={handleradiochange}
                checked={radiovalstate.Shop}
                type="checkbox"
                id="rd3"
                name="Shop"
              />
              <label htmlFor="rd3">Shop</label>
            </article>
            <Menu.Item className="form-text-affected">
              ETOPS Affected:
            </Menu.Item>
            <article className="radio-button-5">
              <input
                onClick={handleradiochange1}
                checked={radiovalues.rd4 === "ETYES"}
                value={"ETYES"}
                type="radio"
                id="rd1"
                name="rd4"
              />
              <label htmlFor="rd1">Yes</label>
              <input
                onClick={handleradiochange1}
                checked={radiovalues.rd4 === "ETNO"}
                value={"ETNO"}
                type="radio"
                className="margin"
                id="rd2"
                name="rd4"
              />
              <label htmlFor="rd2">No</label>
            </article>
            {/* all buttons */}
            <Button className="edit">
              <img src={create_rounded} alt="edit logo" height={20} />
              <div>Edit</div>
            </Button>
            <Button onClick={handelclearevent} className="clear">
              <img src={delete_forever_rounded} alt="clear logo" height={20} />
              <div>Clear</div>
            </Button>
            {/* <Link to={"/formfield2"}> */}
            <Button onClick={handelNextBtn} className="next">
              <div>Next</div>
              <img src={arrow_forward} alt="next" height={23} />
            </Button>
            {/* </Link> */}
          </Form>
        </Menu>
      </div>
    </>
  );
};

export default FormField;