import React from "react";
import Header from "../Components/Header/Header";
import FormField from "../Components/Form/FromField";
// import File from "../Components/File/UploadFile";
import TableList from "../Components/File/TableFile";
import Files from "../Components/File/ListFiles";
const Page1 = () => {
  /* Component is used as a loading in webapp for header ,fileupload,formfiled */

  return (
    <div id="body">
      <div className="heade-area">
        <Header />
      </div>
      {/* <div className="file-upload">
        <File />
      </div> */}
      <div>
        <FormField />
      </div>
      <div className="file-list">
        <Files />
      </div>
      {/* <div className="table-list">
        <TableList />
      </div> */}
    </div>
  );
};

export default Page1;
