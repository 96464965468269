import React, { useState, useEffect } from "react";
import axios from "axios";
import KeycloakUserService from "../login/KeyCloakService";
import { v4 as uuidv4 } from 'uuid';

const Files = () => {
  const [files, setFiles] = useState([]);
  const [filesWithStatus, setFileStatus] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      let userName = KeycloakUserService.getUsername();
      let session_id;
          if(localStorage.getItem("session")) {
            session_id = localStorage.getItem("session");
          } else {
            session_id = uuidv4();
            localStorage.setItem("session", session_id);
          }
      axios.put(`${process.env.REACT_APP_FLASK_APP_URL}/files/docx`, {user_id: userName, session_id: session_id})
      .then((res) => {
        let confirmation;
        let fileList = [];
        const regex = /(\d{2}-\d{2}-\d{4})-\d{2}-\d{2}-\d{2}/; // Regular expression to match the date pattern (dd-mm-yyyy)
        for (let i=0; i<res.data.file_list.length; i++) {
          const match = res.data.file_list[i].match(regex); // Find the first occurrence of the date pattern in the filename
          if (match) {
            fileList.push(res.data.file_list[i]);
          }
        }
        function createDateFromDDMMYYYYHHMMSS(dateString) {
          var parts = dateString.split('-');
          var day = parseInt(parts[0], 10);
          var month = parseInt(parts[1], 10) - 1; // Zero-based month
          var year = parseInt(parts[2], 10);
          var hour = parseInt(parts[3], 10);
          var minute = parseInt(parts[4], 10);
          var second = parseInt(parts[5], 10);
        
          return new Date(year, month, day, hour, minute, second);
        }
        function getDateFromFilename(filename) {
          const regex = /(\d{2}-\d{2}-\d{4})-\d{2}-\d{2}-\d{2}/;
          const match = filename.match(regex);
          if (match) {
            const dateString = match[0];
            return createDateFromDDMMYYYYHHMMSS(dateString);
          }
          return null;
        }
        fileList.sort((a, b) => {
          const dateA = getDateFromFilename(a);
          const dateB = getDateFromFilename(b);
          return dateB.getTime() - dateA.getTime();
        });
        const currentDate = new Date();
        const lastDayDocuments = fileList.filter((filename) => {
          const date = getDateFromFilename(filename);
          const diffTime = Math.abs(currentDate.getTime() - date.getTime());
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          return diffDays === 1;
        });
        setFiles(lastDayDocuments);
        for(let i=0; i<res.data.status.length; i++) {
          res.data.status[i].ot_docx_name = res.data.status[i].ot_docx_name.replace(".docx","");
        }
        setFileStatus(res.data.status.reverse());
        if (res.data.status === 'success') {
          confirmation = window.confirm(
            "Your last SB to EI conversion was successfull, You can download the EI from table."
          );
        } else if (res.data.status === 'error') {
          confirmation = window.confirm(
            "Your last SB to EI conversion was failed."
          );
        } else {
          confirmation = false;
        }
        // if (confirmation) {
        //   axios.put(`${process.env.REACT_APP_FLASK_APP_URL}/status_reset`,{},{ withCredentials: true });
        //   confirmation = false;
        // }
      }).catch((error) => {
        console.error("Error:", error);
      });
    };
    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
    <div className="list-scroll">
    <table>
        <thead>
          <tr>
            <th>Engineering Instructions(DOCX files)</th>
          </tr>
        </thead>
        <tbody>
          {filesWithStatus.map((item, index) => (
            <tr>
              <td>{
                item.status == "success" ?
                <p>
                    {item.ot_docx_name} <br/><br/>
                    status: <a style={{color: 'green'}}> Done</a><br/>
                    <a> user : {item.user_id}</a><br/>
                    <a>timeStamp : {item.time}</a><br/>
                    <a>message : {item.message}</a>
                    <a
                    href={`${process.env.REACT_APP_FLASK_APP_URL}/download/${item.ot_docx_name}`}
                    className="download-file-link"
                    target={"_blank"} rel="noreferrer"
                    >
                      Click here to download
                    </a><br/>
                </p>
                :
                <></>
                }
                {
                item.status == "downloaded" ?
                <p>
                    {item.ot_docx_name} <br/><br/>
                    status: <a style={{color: 'green'}}> Downloaded</a><br/>
                    <a> user : {item.user_id}</a><br/>
                    <a>timeStamp : {item.time}</a><br/>
                    <a>message : {item.message}</a>
                </p>
                :
                <></>
                }
                {
                  item.status == "error" ? 
                  <p>
                    {item.og_pdf_name} <br/><br/>
                    status: <a style={{color: 'red'}}> Failed</a><br/>
                    <a> user : {item.user_id}</a><br/>
                    <a>timeStamp : {item.time}</a><br/>
                    <a style={{color: 'red'}}>Error : {item.message}</a>
                  </p>:
                  <></>
                }
                {
                  (item.status !== "error" && item.status !== "success" && item.status !== "downloaded") ?
                  <p>
                    {item.og_pdf_name} <br/><br/>
                    status : <a style={{color: 'orange'}}> In progress</a><br/>
                    progress : <a style={{color: 'orange'}}> {item.status}%</a><br/>
                    <a> user : {item.user_id}</a><br/>
                    <a>timeStamp : {item.time}</a><br/>
                    <a>message : {item.message}</a>
                  </p>:
                  <></>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  );
};

export default Files;
