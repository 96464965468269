import React from "react";
import Header from "../Components/Header/Header";
import Thankyou from "../Components/ThankYou/ThankYou";

const Page3 = () => {
    /* Component is used as a loading in webapp header with thank you popup */
    return (
    <div>
      <div className="heade-area">
        <Header />
      </div>
      <div>
        <Thankyou />
      </div>
    </div>
  );
};
export default Page3;
